
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import { now, toDateString } from "@common/date";
	import { login } from "./Login";

	import type { NavigationGuardNext, Route } from "vue-router";
	import type { LoginData, LoginResult } from "@/models/Login";
	import type IconInput from "@common/components/form/IconInput.vue";

	// 用來快取表單資料的靜態變數
	let cache: LoginData | null = null;

	@Component
	export default class extends Vue {
		$refs!: {
			phone: IconInput
		};

		@Inject() public readonly alert!: AlertMethod;

		@Prop(String) public username?: string;

		protected data: LoginData = {
			username: "",
			otp: "",
			password: "",
			confirmPassword: "",
			name: "",
			gender: "",
			birthday: null,
			terms: false,
		};
		protected max!: string;
		protected init!: Date;

		protected async onOtp(): Promise<string | undefined> {
			if(await this.$refs.phone.valid()) return this.data.username;
			return undefined;
		}

		beforeMount(): void {
			// 還原表單並且清除快取
			if(cache) this.data = cache;
			cache = null;

			if(this.username) this.data.username = this.username;

			let date = now();
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			date.setFullYear(date.getFullYear() - 20);
			date.setMonth(0, 1);
			this.init = date;
			this.max = toDateString(now());
		}

		beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
			// 只有當即將前往條款頁面的時候才記憶資料
			if(to.path == '/login/terms') cache = this.data;
			next();
		}

		protected async send(): Promise<void> {
			let result = await post<LoginResult>('/api/login/register', {
				Username: this.data.username,
				Password: this.data.password,
				Gender: this.data.gender,
				OTP: this.data.otp,
				Birthday: this.data.birthday,
				name: this.data.name,
			}, 15000);
			login(result, this.data.username);
			await this.alert('註冊成功', undefined, 'success');
			this.$router.replace('/');
		}
	}
