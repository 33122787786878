
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import { TEN_SECONDS } from "@common/communication";

	import type { LoginResult } from "@/models/Login";
	import type IconInput from "@common/components/form/IconInput.vue";

	@Component
	export default class extends Vue {
		$refs!: {
			phone: IconInput
		};

		@Inject() public readonly alert!: AlertMethod;

		@Prop(String) public callback?: string;

		protected username: string = "";
		protected otp: string = "";
		protected password: string = "";
		protected confirmPassword: string = "";

		protected async onOtp(): Promise<string | undefined> {
			if(await this.$refs.phone.valid()) return this.username;
			return undefined;
		}

		protected async send(): Promise<void> {
			await post<LoginResult>('/api/Login/Forget', {
				Username: this.username,
				Password: this.password,
				OTP: this.otp,
			}, TEN_SECONDS);
			await this.alert("請用新密碼重新登入", "重設密碼成功", 'success');
			this.$router.replace('/');
		}
	}
